import React, { useState } from "react";
import {
  Pagination,
  PaginationButton,
  RowSelection,
  RowSelectionLabel,
  RowSelectionSelect,
  Search,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableHeaderCell,
  TableHeaderCellContent,
  TableInputNeumorphic,
  TableOptions,
  TableRow,
  TableRowFooter,
} from "./Table.styled";

export type ITableDataType = {
  key: string;
  label: string;
};

export type ITableProps = {
  columns: ITableDataType[];
  body: any[];
};

function TableComponent({ columns, body }: ITableProps) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortAscending, setSortAscending] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = body.filter(
    (row: { [s: string]: unknown } | ArrayLike<unknown>) =>
      Object.values(row).some((value) =>
        typeof value === "string"
          ? value.toLowerCase().includes(searchQuery.toLowerCase())
          : ""
      )
  );

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const sortedData = filteredData
    .slice()
    .sort((a: { [x: string]: number }, b: { [x: string]: number }) => {
      if (sortBy === null) {
        return 0;
      }
      if (a[sortBy] < b[sortBy]) {
        return sortAscending ? -1 : 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return sortAscending ? 1 : -1;
      }
      return 0;
    });

  const paginatedData = sortedData.slice(startIndex, endIndex);

  const handleSort = (key: string) => {
    if (sortBy === key) {
      setSortAscending(!sortAscending);
    } else {
      setSortBy(key);
      setSortAscending(true);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  return (
    <TableContainer>
      <TableOptions>
        <RowSelection>
          <RowSelectionLabel>Rows per page:</RowSelectionLabel>
          <RowSelectionSelect
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </RowSelectionSelect>
        </RowSelection>
        <Search>
          <TableInputNeumorphic
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </Search>
      </TableOptions>

      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell
                key={column.key}
                onClick={() => handleSort(column.key)}
              >
                <TableHeaderCellContent>
                  {column.label}
                  {sortBy === column.key && (
                    <span>{sortAscending ? "⬆️" : "⬇️"}</span>
                  )}
                </TableHeaderCellContent>
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <tbody>
          {paginatedData.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell key={column.key}>{row[column.key]}</TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
        <TableFooter>
          <TableRowFooter>
            <TableCell colSpan={columns.length}>
              <Pagination>
                <PaginationButton
                  disabled={page === 1}
                  onClick={() => {
                    setPage(page - 1);
                  }}
                >
                  <span
                    style={{ fontSize: "inherit" }}
                    className="material-icons"
                  >
                    arrow_back_ios
                  </span>
                </PaginationButton>
                <PaginationButton
                  disabled={endIndex >= body.length}
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  <span
                    style={{ fontSize: "inherit" }}
                    className="material-icons"
                  >
                    arrow_forward_ios
                  </span>
                </PaginationButton>
              </Pagination>
            </TableCell>
          </TableRowFooter>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default TableComponent;
