import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import Drawer from "../Drawer/Drawer";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import background_am from "../../assets/images/morpheus_dreams_god.png";
import { openDrawerMobile } from "../../store/applications/UIReducer";
import { routes, routesFR } from "../../routes";

const LayoutBox = styled.div`
  /* &::before {
    content: "";
    background-image: url(${background_am});
    background-size: cover;
    background-position: center center;
    position: fixed;
    left: 0px;
    width: 100%;
    height: calc(100%);
    opacity: 0.4;
    z-index: -1;
  } */
`;

const Screen = styled.div`
  opacity: 1;
  background: transparent;
  // color: white;
  padding: 10px;
  position: relative;
  transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  /* @media screen and (min-width: 992px) {
    margin-left: ${(props: { drawerDesktop: boolean }) =>
    props.drawerDesktop ? "17.125rem !important" : "0rem"};
  } */

  @media screen and (min-width: 768px) {
    // margin-left: 7.5rem;
  }
`;

export const AppBox = styled.div`
  margin-top: 5px;
  min-height: 80vh;
  opacity: 1;
  background: transparent;
`;

function BaseLayout({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  // const drawerState = useAppSelector((state) => state.UIReducer);
  const drawerStateDesktop = useAppSelector(
    (state) => state.UIReducer.drawerDesktop
  );
  const drawerStateMobile = useAppSelector(
    (state) => state.UIReducer.drawerMobile
  );
  const language = useAppSelector((state) => state.UIReducer.language);

  useEffect(() => {}, [drawerStateDesktop]);

  const handleClick = () => {
    if (drawerStateMobile) {
      dispatch(openDrawerMobile(!drawerStateMobile));
    }
  };

  return (
    <LayoutBox>
      <Drawer
        // drawerDesktop={drawerStateDesktop}
        drawerDesktop={drawerStateMobile}
        drawerMobile={drawerStateMobile}
        routes={language === "fr" ? routesFR : routes}
      />
      <Screen drawerDesktop={drawerStateDesktop}>
        <Header />
        <AppBox onClick={handleClick}>{children}</AppBox>
        <Footer />
      </Screen>
    </LayoutBox>
  );
}

export default BaseLayout;
