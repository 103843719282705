import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { loadAuth } from "../../store/applications/AuthReducer";
import chatV2getWL from "../../utils/api/chatV2getWL";
import chatV2getWLadmin from "../../utils/api/chatV2getWLadmin";
import chatV2postWLadmin from "../../utils/api/chatV2postWLadmin";
import ButtonNeumorphicRoot from "../../components/ButtonNeumorphic/ButtonNeumorphic.styled";

function InterpreterV2admin() {
  const dispatch = useAppDispatch();
  const authData = useAppSelector((state) => state.AuthReducer);
  // eslint-disable-next-line
  const [uid, setUid] = useState<string | null>(
    "" || window.localStorage.getItem("uid-askmorpheus")
  );
  // eslint-disable-next-line
  const [wl, setWl] = useState<string[]>([]);
  const [wlAdded, setWlAdded] = useState(0);

  const getWl = async () => {
    const whitelist = await chatV2getWL();

    console.log(Object.values(whitelist));
    return Object.values(whitelist);
  };

  const getPendingWl = async () => {
    const whitelist = await chatV2getWLadmin();

    console.log(Object.values(whitelist));
    setWl(Object.values(whitelist));
    return Object.values(whitelist);
  };

  // eslint-disable-next-line
  const handleAddWl = async (u: string) => {
    const wl = await chatV2postWLadmin(u).finally(() => {
      setWlAdded(wlAdded + 1);
    });
    return wl;
  };

  useEffect(() => {
    dispatch(loadAuth());
    getWl();
    getPendingWl();
    if (uid === "" || (uid === null && authData.uid !== "")) {
      setUid(authData.uid);
    }
  }, [uid, wlAdded]); // eslint-disable-line

  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {wl.map((w, i) => {
        return (
          <ButtonNeumorphicRoot key={i} onClick={() => handleAddWl(w)}>
            {w}
          </ButtonNeumorphicRoot>
        );
      })}
    </div>
  );
}

export default InterpreterV2admin;
