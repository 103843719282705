import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ButtonMorpheus } from "../../components/ButtonMorpheus/ButtonMorpheus.styled";
import { ChatBox, LoaderBox, SendButtonBox } from "../Homepage/HomepageFR";
import { InterpretationBox } from "../../components/Interpretation/Interpretation.style";
import { LogoImg } from "../../components/Header/Header";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
// import { Label, Textarea } from "../../components/UI/Forms/Forms.styled";
// import Divider from "../../components/Divider/Divider";
import { Dna } from "react-loader-spinner";
import { loadAuth } from "../../store/applications/AuthReducer";
// import chat from "../../utils/api/chat";
import TypingText from "../../components/TypingText/TypingText";
import TextAreaResizable from "../../components/TextAreaResizable/TextAreaResizable";
import morpheus from "../../assets/images/askmorpheus-logo-v2-1.png";
import chatV2start from "../../utils/api/chatV2start";
import chatV2 from "../../utils/api/chatV2";
import chatV2getAll from "../../utils/api/chatV2getAll";
import { useParams } from "react-router-dom";
import chatV2getChat from "../../utils/api/chatV2getChat";
import chatV2getWL from "../../utils/api/chatV2getWL";
import { MQEA } from "../../components/UI/Text/mqea/mqea.styled";
import chatV2postWL from "../../utils/api/chatV2postWL";
import chatV2getWLadmin from "../../utils/api/chatV2getWLadmin";
import Versions from "../../components/Versions/Versions";
import { palette } from "../../assets/style/colors";

interface IChatList {
  [key: string]: IChatThread; // Use an index signature for dynamic keys like "-NdSYdHKsWg-n9wMT5I6"
}

interface IChatThread {
  thread: {
    content: string;
    role: string; // You can use union types for predefined string values
    type: string;
  }[];
}

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChatPageComponent = styled.div`
  display: flex;
  height: 80vh;
  width: 100%;
  overflow-y: hidden;
  position: relative;
  flex-direction: column;
`;

export interface IChatNav {
  displayMobile: boolean;
}

const ChatNavComponent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 260px;
  height: 80vh;
  background-color: ${palette.secondary.background};

  @media screen and (max-width: 682px) {
    // display: none;
    width: ${(props: IChatNav) => (props.displayMobile ? "400px" : "0px")};
    transform: ${(props: IChatNav) =>
      props.displayMobile ? "translateX(0px)" : "translateX(-400px)"};
    transition: transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
`;

const ChatNavAction = styled.div``;

const ChatNavButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  margin: 5px;
  padding: 0.5rem;
  color: rgba(255, 255, 255);
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 20%);
  }
`;

const ChatNavHistory = styled.div`
  overflow-y: auto;
  padding-bottom: 10rem;
`;

const ChatLinkBox = styled.a`
  text-decoration: none;
  word-break: break-all;
  font-size: 1rem;
  padding: 0.75rem 0.5rem;
  text-overflow: ellipsis;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;

  &:hover {
    background-color: rgba(255, 255, 255, 15%);
  }
`;

const ChatLink = styled.div`
  overflow: hidden;
  max-height: 1.25rem;
  color: ${palette.secondary.headline};
`;

const ChatMessagesComponent = styled.div`
  overflow-y: auto;
  padding-bottom: 10rem;
  transition: all ease-in-out 200ms;
  scroll-behavior: smooth;
`;

const UserInputAreaComponent = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-image: linear-gradient(
    rgba(53, 55, 64, 0),
    rgb(53, 55, 64) 58.85%
  );
  border-color: transparent;
  padding-left: 0.5rem;
  padding-top: 5rem;
  width: calc(100% - 0.5rem);
`;

const ChatDivContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  max-width: 42rem;
  margin: auto;
`;

const ChatDivContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const MessageBotBox = styled.div`
  background-color: rgba(255, 255, 255, 20%);
  padding: 1rem 0;
`;

const MessageUserBox = styled.div`
  background-color: #03081c;
  padding: 1rem 0;
`;

const MessageBoxCenter = styled.div`
  max-width: 42rem;
  margin: auto;
`;

const MessageBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;

  line-height: 1.5rem;
`;

const FormUserBox2 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

const FormBox = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

const TextAreaBox = styled.div`
  display: flex;
  flex-direction: column;
`;

function InterpretV2() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const myRef = useRef<HTMLDivElement | null>(null);
  const authData = useAppSelector((state) => state.AuthReducer);
  const uiState = useAppSelector((state) => state.UIReducer);
  // eslint-disable-next-line
  const [uid, setUid] = useState<string | null>(
    "" || window.localStorage.getItem("uid-askmorpheus")
  );
  const [firstname, setFirstname] = useState<string | null>("");
  const [lastname, setLastname] = useState<string | null>("");
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [textUser, setTextUser] = useState("");
  const [dream, setDream] = useState("");
  const [context, setContext] = useState("");
  const [chatId, setChatId] = useState("");
  const [update, setUpdate] = useState(0);
  const [chatsAll, setChatsAll] = useState<IChatList>();
  const [chat, setChat] = useState<IChatThread>();
  // eslint-disable-next-line
  const [chatsLoaded, setChatsLoaded] = useState(false);
  const [chatLoaded, setChatLoaded] = useState(false);
  const [wl, setWl] = useState(false);
  const [wlPending, setWlPending] = useState(false);
  const [wlLoading, setWlLoading] = useState(false);

  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false);
    }, 2000);
  }, [loadingPage]);

  const getWl = async () => {
    const whitelist = await chatV2getWL();

    console.log(Object.values(whitelist));
    return Object.values(whitelist);
  };

  const getPendingWl = async () => {
    const whitelist = await chatV2getWLadmin();

    console.log(Object.values(whitelist));
    return Object.values(whitelist);
  };

  const handleAddWl = async () => {
    const wl = chatV2postWL(uid, authData.prenom, authData.nom).then(() => {
      setWlPending(true);
    });
    return wl;
  };

  const getAllChats = async () => {
    setChatsLoaded(false);
    const allChats = uid && (await chatV2getAll(uid));
    setChatsAll(allChats);
    setChatsLoaded(true);
    return allChats;
  };

  const { chatIdParam } = params;

  const getChat = async () => {
    // setChatLoaded(false);
    console.log("Load chat for :", chatIdParam);
    const chatRaw =
      uid && chatIdParam && (await chatV2getChat(uid, chatIdParam));
    console.log(chatRaw);
    setChat(chatRaw);
    // setChatLoaded(true);
    return chatRaw;
  };

  useEffect(() => {
    try {
      getAllChats();
      getChat()
        .then((res) => setChat(res))
        .finally(() => setChatLoaded(true));
      const thread = chat?.thread;
      for (let c in thread) {
        thread[Number(c)].type = "stored";
      }
      chat?.thread && setMessages(chat?.thread);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      if (myRef.current) {
        myRef.current.scrollTop = myRef.current.scrollHeight;
      }
    }, 2000);
  }, [chatLoaded, uid, firstname, lastname, chatIdParam]); // eslint-disable-line

  useEffect(() => {
    dispatch(loadAuth());
    getWl().then((res) => {
      if (
        authData.email &&
        res.includes(`${uid}-${authData.prenom}-${authData.nom}`)
      ) {
        setWl(true);
      }
    });
    getPendingWl()
      .then((res) => {
        if (
          authData.email &&
          res.includes(`${uid}-${authData.prenom}-${authData.nom}`)
        ) {
          setWlPending(true);
        }
      })
      .finally(() => setWlLoading(true));
    if (uid === "" || (uid === null && authData.uid !== "")) {
      setUid(authData.uid);
      try {
        getAllChats();
      } catch (error) {
        console.log(error);
      }
    }

    setFirstname(authData.prenom);
    setLastname(authData.nom);
  }, [uid, firstname, lastname, authData.email, wlPending, wlLoading]); // eslint-disable-line

  const contextMessage = {
    role: "assistant",
    content:
      "Merci ! Pourriez-vous partager un peu de votre contexte personnel ? Vos préoccupations du moment ? Tout élément significatif qui pourrait me permettre de vous offrir une interprétation des plus précises.",
    type: "loaded",
  };

  const [messages, setMessages] = useState<
    Array<{ role: string; content: string; type: string }>
  >([
    {
      role: "assistant",
      content:
        "Bonjour, je suis Morpheus. Ayant été formé dans le domaine de l'analyse des rêves avec la méthode Freudienne, je suis en mesure de vous guider dans l'interprétation de vos songes. Pourriez-vous partager votre rêve avec le maximum de détails possibles ?",
      type: "stored",
    },
  ]);

  const handleSendMessage = async (text: string) => {
    console.log("dream :", dream);
    console.log("context :", context);
    console.log("chatIdParam :", chatIdParam);
    console.log("chatId :", chatId);
    if (dream && !context) {
      console.log(text);
      console.log(textUser);
      setContext(text);
      const message = {
        role: "user",
        content: textUser,
        type: "loaded",
      };
      setMessages((prevMessages) => [...prevMessages, message]);

      setTextUser("");
      setLoading(true);
      const response = await chatV2start(dream, context, uid);
      console.log(response);

      const processResponse = async (response: Response) => {
        if (window.TextDecoderStream) {
          console.log("STREAM CHROME");
          const reader = response.body
            ?.pipeThrough(new TextDecoderStream())
            ?.getReader();
          let received = "";
          if (reader) {
            while (true) {
              const { value, done } = await reader.read();
              if (done) break;
              received += value;
              setLoading(false);
              setUpdate(update + 1);

              console.log(value);
              if (value.includes("chatId")) {
                const lines = received.split("\nchatId: ");
                console.log("id ", lines[1]);
                setChatId(lines[1]);
                const _answer = lines[0];
                const answer = {
                  role: "assistant",
                  content: _answer,
                  type: "loaded",
                };
                setMessages((prevMessages) => [...prevMessages, answer]);
              }
            }
          }
        } else {
          console.log("STREAM NONE CHROME");
          const data = await response.arrayBuffer();
          const text = new TextDecoder().decode(data);

          if (text.includes("chatId")) {
            const id = text.replace("\nchatId: ", "");
            setChatId(id);

            const lines = text.split("\nchatId: ");
            const _answer = lines[0];
            const answer = {
              role: "assistant",
              content: _answer,
              type: "loaded",
            };
            setMessages((prevMessages) => [...prevMessages, answer]);
          }
          setLoading(false);
        }
      };

      await processResponse(response);
    } else if (dream && context) {
      const message = {
        role: "user",
        content: textUser,
        type: "stored",
      };
      setMessages((prevMessages) => [...prevMessages, message]);

      setTextUser("");
      setLoading(true);
      const response = await chatV2(textUser, chatId, uid);
      setTextUser("");
      console.log(response);

      if (window.TextDecoderStream) {
        const reader = response.body
          ?.pipeThrough(new TextDecoderStream())
          ?.getReader();
        // let received = "";
        setLoading(false);
        if (reader) {
          while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            const answer = {
              role: "assistant",
              content: value,
              type: "loaded",
            };

            setMessages((prevMessages) => [...prevMessages, answer]);
          }
        }
      } else {
        // Fallback or notify the user that their browser isn't supported.
        const data = await response.arrayBuffer();
        const text = new TextDecoder().decode(data);

        const answer = {
          role: "assistant",
          content: text,
          type: "loaded",
        };

        setMessages((prevMessages) => [...prevMessages, answer]);

        setLoading(false);
      }
    } else {
      const message = {
        role: "user",
        content: text,
        type: "stored",
      };

      setMessages((prevMessages) => [...prevMessages, message]);
      setDream(text);
      setTextUser("");
      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, contextMessage]);
      }, 1000); // 1-second delay
    }
  };
  useEffect(() => {}, [update, context, chatId]); // eslint-disable-line
  if (loadingPage) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Dna />
      </div>
    );
  } else if (uid && wl) {
    return (
      <FlexRow>
        <ChatNavComponent displayMobile={uiState.chatNavMobile}>
          <ChatNavAction>
            <a style={{ textDecoration: "none" }} href="/v2">
              <ChatNavButton>
                <span className="material-icons">add</span>Nouveau Rêve
              </ChatNavButton>
            </a>
          </ChatNavAction>
          <ChatNavHistory>
            {chatsAll &&
              Object.keys(chatsAll).map((k, i) => {
                return (
                  <ChatLinkBox key={i} href={`/v2/c/${k}`}>
                    <ChatLink>{`💬 ${k}`}</ChatLink>
                  </ChatLinkBox>
                );
              })}
          </ChatNavHistory>
        </ChatNavComponent>
        <ChatPageComponent>
          <ChatMessagesComponent ref={myRef} id="chatSection">
            <ChatDivContainerCol>
              {messages.map((m, i) => {
                if (m.role === "assistant") {
                  if (m.type === "loaded") {
                    return (
                      <MessageBotBox key={i}>
                        <MessageBoxCenter>
                          <MessageBox>
                            <LogoImg src={morpheus} />
                            <TypingText text={m.content} />
                          </MessageBox>
                        </MessageBoxCenter>
                      </MessageBotBox>
                    );
                  } else {
                    return (
                      <MessageBotBox key={i}>
                        <MessageBoxCenter>
                          <MessageBox>
                            <LogoImg src={morpheus} />
                            <span>{m.content}</span>
                          </MessageBox>
                        </MessageBoxCenter>
                      </MessageBotBox>
                    );
                  }
                } else if (m.role === "user") {
                  return (
                    <MessageUserBox key={i}>
                      <MessageBoxCenter>
                        <MessageBox>
                          <LogoImg src={authData.pic} />
                          <span>{m.content}</span>
                        </MessageBox>
                      </MessageBoxCenter>
                    </MessageUserBox>
                  );
                } else {
                  return null;
                }
              })}
            </ChatDivContainerCol>
          </ChatMessagesComponent>
          {/* T E X T A R E A */}
          <UserInputAreaComponent>
            <ChatDivContainerRow>
              <FormUserBox2>
                <div></div>
                <ChatBox>
                  <InterpretationBox>
                    {authData && authData.pic && <LogoImg src={authData.pic} />}
                    <FormBox>
                      <TextAreaBox>
                        <TextAreaResizable
                          onChange={setTextUser}
                          value={textUser}
                          // onKeyDown={handleKeyDown}
                          // placeholder="Écrivez ici votre rêve"
                        />
                      </TextAreaBox>
                      {loading ? (
                        <LoaderBox>
                          <Dna
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                          />
                        </LoaderBox>
                      ) : textUser.length > 2 ? (
                        <SendButtonBox>
                          <ButtonMorpheus
                            onClick={() => handleSendMessage(textUser)}
                          >
                            <span
                              className="material-icons"
                              style={{ fontSize: "inherit" }}
                            >
                              send
                            </span>
                          </ButtonMorpheus>
                        </SendButtonBox>
                      ) : (
                        <SendButtonBox>
                          <ButtonMorpheus disabled>
                            <span
                              className="material-icons"
                              style={{ fontSize: "inherit" }}
                            >
                              send
                            </span>
                          </ButtonMorpheus>
                        </SendButtonBox>
                      )}
                    </FormBox>
                  </InterpretationBox>
                </ChatBox>
              </FormUserBox2>
            </ChatDivContainerRow>
          </UserInputAreaComponent>
        </ChatPageComponent>
      </FlexRow>
    );
  } else if (uid && !wlPending) {
    return (
      <>
        <FlexRow style={{ justifyContent: "center" }}>
          <Versions whichVersion={2} />
        </FlexRow>
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              textAlign: "center",
              maxWidth: "500px",
              lineHeight: "1.5rem",
            }}
          >
            ✅ Exclusivité ! La V2 d'<MQEA>ASK MORPHEUS</MQEA> est désormais
            accessible en accès limité. Profitez d'un entretien analytique
            détaillé, à la manière d'un psychanalyste, pour explorer et
            comprendre les profondeurs de votre subconscient et en saisir les
            significations. Plongez dans une interprétation adaptée à votre
            psyché. Rejoignez la démo privée maintenant!
          </span>
          {firstname && (
            <ButtonMorpheus onClick={handleAddWl}>Rejoindre 🚪</ButtonMorpheus>
          )}
        </div>
      </>
    );
  } else if (uid && wlPending) {
    return (
      <>
        <FlexRow style={{ justifyContent: "center" }}>
          <Versions whichVersion={2} />
        </FlexRow>
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              textAlign: "center",
              maxWidth: "500px",
              lineHeight: "1.5rem",
            }}
          >
            🎉 <MQEA>Félicitations</MQEA> ! Vous êtes inscrit dans la liste pour
            rejoindre la démo privée. Nous allons procéder prochainement à la
            validation de votre inscription. Merci!
          </span>
        </div>
      </>
    );
  } else {
    return (
      <>
        <FlexRow style={{ justifyContent: "center" }}>
          <Versions whichVersion={2} />
        </FlexRow>
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ textAlign: "center", lineHeight: "1.5rem" }}>
            Vous devez être connecté.e avec votre compte pour accéder à la V2
          </span>
          <a href="/fr/login" style={{ textDecoration: "none" }}>
            <ButtonMorpheus>Se Connecter</ButtonMorpheus>
          </a>
        </div>
      </>
    );
  }
}

export default InterpretV2;
