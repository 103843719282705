import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { timestampToDate, truncate } from "../../utils";
import { loadAuth } from "../../store/applications/AuthReducer";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import readDreamsUser from "../../utils/api/readDreamsUser";
import {
  PageTitle,
  PageTitleBox,
} from "../../components/Paragraph/Paragraph.styled";
import { BoxRow } from "../../components/Box/Box.styled";
import ButtonNeumorphicRoot from "../../components/ButtonNeumorphic/ButtonNeumorphic.styled";
import postArchiveDream from "../../utils/api/postArchiveDream";

export interface IDreams {
  createdAt: string;
  dream: string;
  interpretation: string;
  style: string;
  id: string;
  status?: string;
  relevant?: boolean;
}

export const CollapseComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CollapseBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin-bottom: 1rem;
`;
export const CollapseHeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: ${(props: ICollapseBodyBox) =>
    props.isActive ? "1rem 1rem 0rem 0rem" : "1rem"};
  width: 100%;
  padding: 0.6rem;
  cursor: pointer;
`;

export const Chip = styled.div`
  font-size: 0.7rem;
  border-radius: 1rem;
  padding: 6px 10px;
  background-color: #ff00a2;
  margin: 4px 0px;
`;

export const TextMini = styled.p`
  font-size: 0.7rem;
  padding: 6px 10px;
  margin: 4px 0px;
`;

export const CollapseHeaderOptionsBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface ICollapseBodyBox {
  isActive?: boolean;
}
export const CollapseBodyBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0rem 0rem 1rem 1rem;
  width: 100%;
  padding: 1rem 0.6rem;

  display: ${(props: ICollapseBodyBox) => (props.isActive ? "block" : "none")};
`;

function DreamsList() {
  const dispatch = useAppDispatch();
  const authData = useAppSelector((state) => state.AuthReducer);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dreams, setDreams] = useState<IDreams[]>([]);
  const [updateDreams, setUpdateDreams] = useState(0);
  const handleClick = (index: number) => {
    if (index === activeIndex) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };

  const getUserDream = async () => {
    await readDreamsUser(authData.uid).then((res) => {
      setDreams(res);
    });
  };

  const handleDelete = async (dreamId: string) => {
    try {
      await postArchiveDream(authData.uid, dreamId).then(() => {
        setUpdateDreams(updateDreams + 1);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(loadAuth());
    authData.uid && getUserDream();
  }, [updateDreams]); // eslint-disable-line
  return (
    <>
      <PageTitleBox>
        <PageTitle>My Dreams</PageTitle>
      </PageTitleBox>
      <CollapseComponent>
        {Object.values(dreams).map((dream, index) => {
          return (
            <CollapseBox key={index}>
              <CollapseHeaderBox isActive={activeIndex === index}>
                <CollapseHeaderOptionsBox>
                  <BoxRow>
                    <Chip>{dream.style}</Chip>
                  </BoxRow>

                  <BoxRow>
                    <TextMini>
                      {timestampToDate(Number(dream.createdAt))}
                    </TextMini>
                    <ButtonNeumorphicRoot
                      onClick={() => handleDelete(dream.id)}
                    >
                      <span
                        style={{ fontSize: "inherit" }}
                        className="material-icons"
                      >
                        delete
                      </span>
                    </ButtonNeumorphicRoot>
                  </BoxRow>
                </CollapseHeaderOptionsBox>
                <div onClick={() => handleClick(index)}>
                  {activeIndex === index ? dream.dream : truncate(dream.dream)}
                </div>
              </CollapseHeaderBox>
              <CollapseBodyBox isActive={activeIndex === index}>
                {dream.interpretation}
              </CollapseBodyBox>
            </CollapseBox>
          );
        })}
      </CollapseComponent>
    </>
  );
}

export default DreamsList;
