import axios from "axios";

const chatV2getWLadmin = async () => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/v1/wl/pending`;
  const response = await axios.get(url);
  return response.data;
};

export default chatV2getWLadmin;
