import React from "react";
import styled from "styled-components";
import { LogoLang } from "../../pages/Homepage/HomepageFR";
import flagEn from "../../assets/images/us-en-flag.png";
import flagFr from "../../assets/images/fr-fr-flag.png";

const ButtonLanguageBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 1rem;
  padding: 0.2rem;
  margin: 0.5rem 1rem;
  width: 50vw;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px,
    rgba(255, 255, 255, 0.5) -2px -2px 5px 0px;

  @media screen and (max-width: 682px) {
    width: 85vw;
  }
`;

function ButtonLanguage(props: { language: "en" | "fr" }) {
  if (props.language === "en") {
    return (
      <a href="/en">
        <ButtonLanguageBox>
          <LogoLang src={flagEn} />
          <p>Visit english version</p>
          <span className="material-icons-outlined">arrow_right</span>
        </ButtonLanguageBox>
      </a>
    );
  } else if (props.language === "fr") {
    return (
      <a href="/fr">
        <ButtonLanguageBox>
          <LogoLang src={flagFr} />
          <p>Visiter la version française</p>
          <span className="material-icons-outlined">arrow_right</span>
        </ButtonLanguageBox>
      </a>
    );
  } else {
    return (
      <a href="/fr">
        <ButtonLanguageBox>
          <LogoLang src={flagFr} />
          <p>Visiter la version française</p>
          <span className="material-icons-outlined">arrow_right</span>
        </ButtonLanguageBox>
      </a>
    );
  }
}

export default ButtonLanguage;
