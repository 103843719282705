export const palette = {
  primary: {
    background: "#abd1c6",
    backgroundMenu: "#dfebe7",
    headline: "#001e1d",
    paragraph: "#0f3433",
    paragraphLight: "#abd1c6",
    button: "#f9bc60",
    buttonText: "#001e1d",
    tertiary: "#e16162",
    stroke: "#001e1d",
    placeholder: "#001e1d7b",
  },
  secondary: {
    background: "#004643",
    headline: "#fffffe",
    paragraph: "#abd1c6",
    button: "#f9bc60",
    buttonText: "#001e1d",
    tertiary: "#e16162",
  },
};
