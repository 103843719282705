import React, { useEffect, useState } from "react";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import type { ChartData, ChartOptions } from "chart.js";
import { useAppDispatch } from "../../hooks/reduxHooks";
// import { readPageSpeed } from "../../utils/api/readPageSpeed";
import {
  PageTitle,
  PageTitleBox,
} from "../../components/Paragraph/Paragraph.styled";
import { loadAuth } from "../../store/applications/AuthReducer";
import readDreamsFree from "../../utils/api/readDreamsFree";
import { ITableProps } from "../../components/Table/Table";
import TableComponent from "../../components/Table/Table";
import { timestampToDate } from "../../utils";

const tableBodyData: ITableProps = {
  columns: [
    {
      key: "date",
      label: "Date",
    },
    {
      key: "style",
      label: "Method",
    },
    {
      key: "dream",
      label: "Dream",
    },
    {
      key: "context",
      label: "Context",
    },
    {
      key: "interpretation",
      label: "Interpretation",
    },
    {
      key: "user",
      label: "IP",
    },
  ],
  body: [],
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

interface LineProps {
  data: ChartData<"line">;
  options?: ChartOptions<"line">;
}

export const options: ChartOptions<"line"> = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Free - Unregistered Users - Dreams",
    },
  },
  scales: {
    x: {
      type: "time",
      min: "2023-06-26 00:00:00",
      time: {
        unit: "day",
        displayFormats: {
          quarter: "MMM YYYY",
        },
      },
      grid: {
        display: false,
        color: "rgb(255 255 255 / 50%)",
      },
    },

    y: {
      grid: {
        display: false,
        color: "rgb(255 255 255 / 50%)",
      },
    },
  },
};

// const data: ChartData<"line"> = {
//   labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
//   datasets: [
//     {
//       label: "Score",
//       data: [80, 90, 70, 95],
//       backgroundColor: "rgba(61,71,217,0.867)",
//       borderColor: "rgba(61,71,217,0.867)",
//       tension: 0.3,
//     },
//   ],
// };

const LineChart: React.FC<LineProps> = ({ data }) => {
  return <Line data={data} options={options} />;
};

let initialChartData: ChartData<"line"> = {
  labels: [],
  datasets: [
    {
      label: "Free-Dreams",
      data: [],
      backgroundColor: "rgba(61,71,217,0.867)",
      borderColor: "rgba(61,71,217,0.867)",
      tension: 0.3,
    },
    // {
    //   label: "LCP",
    //   data: [],
    //   backgroundColor: "rgba(233,124,187,0.867)",
    //   borderColor: "rgba(233,124,187,0.867)",
    //   tension: 0.3,
    //   pointStyle: false,
    // },
    // {
    //   label: "SI",
    //   data: [],
    //   backgroundColor: "rgba(249,231,187,0.867)",
    //   borderColor: "rgba(249,231,187,0.867)",
    //   tension: 0.3,
    //   pointStyle: false,
    // },
  ],
};

function DashboardAdmin() {
  const dispatch = useAppDispatch();
  const [chartData, setChartData] = useState(initialChartData); // initialChartData

  const getFreeDreamsDataSet = async () => {
    await readDreamsFree()
      .then((res) => {
        tableBodyData.body = res;
        console.log(tableBodyData);
        // Sort the dreams in ascending order by createdAt
        tableBodyData.body = tableBodyData.body.sort(
          (a, b) => Number(b.createdAt) - Number(a.createdAt)
        );
        for (let i = 0; i < tableBodyData.body.length; i++) {
          tableBodyData.body[i].date = timestampToDate(
            Number(tableBodyData.body[i].createdAt)
          );
        }
        let dates: number[] = [];
        let datapoint: number[] = [];
        for (let i = 0; i < res.length; i++) {
          console.log(res[i]);
          const timestamp = res[i].createdAt;
          const date = new Date(timestamp);

          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);

          const roundedTimestamp = date.getTime();
          res[i].createdAtDay = roundedTimestamp;
        }
        console.log(res);
        // Use a JavaScript object as a dictionary to sum the y values:
        const summedData = res.reduce(
          (acc: { [x: string]: any }, { createdAtDay, count }: any) => {
            if (acc[createdAtDay]) {
              acc[createdAtDay] += count;
            } else {
              acc[createdAtDay] = count;
            }
            return acc;
          },
          {}
        );

        // Then convert it back to the array of data points format:
        const finalData = Object.entries(summedData).map(
          ([createdAtDay, count]) => ({ createdAtDay, count })
        );
        console.log(finalData);
        for (let i = 0; i < finalData.length; i++) {
          dates.push(Number(finalData[i].createdAtDay));
          datapoint.push(Number(finalData[i].count));
        }
        setChartData({
          ...chartData,
          labels: dates,
          datasets: [
            {
              ...chartData.datasets[0],
              data: datapoint,
            },
          ],
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // useEffect(() => {
  //   getPageSpeedDataSet();
  // }, [isLoading]); // eslint-disable-line

  useEffect(() => {
    dispatch(loadAuth());
    getFreeDreamsDataSet();
  }, []); // eslint-disable-line
  return (
    <>
      <PageTitleBox>
        <PageTitle>Dashboard</PageTitle>
      </PageTitleBox>
      <LineChart data={chartData} options={options} />
      <TableComponent
        columns={tableBodyData.columns}
        body={tableBodyData.body}
      />
    </>
  );
}

export default DashboardAdmin;
