import React, { useState, useRef, useEffect } from "react";
import audioChat from "../../utils/api/audioChat";
import { ButtonMorpheus } from "../../components/ButtonMorpheus/ButtonMorpheus.styled";
import { FlexCol } from "../InterpretV2/InterpretV2";
import { Textarea } from "../../components/UI/Forms/Forms.styled";

function AudioRecorder() {
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [whisper, setWhisper] = useState<string>("");
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
  const [dataArray, setDataArray] = useState<Uint8Array | null>(null);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (recording) {
      const draw = () => {
        if (analyser && dataArray) {
          const canvasCtx = canvasRef.current?.getContext("2d");
          if (!canvasCtx) return;
          const WIDTH = canvasCtx.canvas.width;
          const HEIGHT = canvasCtx.canvas.height;
          requestAnimationFrame(draw);
          analyser.getByteTimeDomainData(dataArray);
          canvasCtx.fillStyle = "#03081c";
          canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
          canvasCtx.lineWidth = 2;
          canvasCtx.strokeStyle = "rgb(206, 159, 111)";
          canvasCtx.beginPath();

          const sliceWidth = (WIDTH * 1.0) / dataArray.length;
          let x = 0;
          for (let i = 0; i < dataArray.length; i++) {
            const v = dataArray[i] / 128.0;
            const y = (v * HEIGHT) / 2;
            if (i === 0) {
              canvasCtx.moveTo(x, y);
            } else {
              canvasCtx.lineTo(x, y);
            }
            x += sliceWidth;
          }

          canvasCtx.lineTo(canvasCtx.canvas.width, canvasCtx.canvas.height / 2);
          canvasCtx.stroke();
        }
      };
      draw();
    }
  }, [recording, analyser, dataArray]);

  const startRecording = async () => {
    const newAudioContext = new AudioContext();
    const newAnalyser = newAudioContext.createAnalyser();
    newAnalyser.fftSize = 2048;
    const bufferLength = newAnalyser.frequencyBinCount;
    const newdataArray = new Uint8Array(bufferLength);

    setAudioContext(newAudioContext);
    setAnalyser(newAnalyser);
    setDataArray(newdataArray);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const sourceNode = newAudioContext.createMediaStreamSource(stream);
    sourceNode.connect(newAnalyser);

    const newMediaRecorder = new MediaRecorder(stream);
    let localChunks: BlobPart[] = [];

    newMediaRecorder.ondataavailable = (e) => {
      localChunks.push(e.data);
    };

    newMediaRecorder.onstop = async () => {
      console.log("mimeType: ", newMediaRecorder.mimeType.split(";")[0]);
      const audioBlob = new Blob(localChunks, {
        type: newMediaRecorder.mimeType.split(";")[0],
      });
      const audioUrl = URL.createObjectURL(audioBlob);
      // Now, we'll call the audioChat function with the generated URL
      await audioChat(audioBlob).then((res) => {
        res && setWhisper(res.toString());
      });
      setAudioURL(audioUrl);
    };

    newMediaRecorder.start();
    mediaRecorderRef.current = newMediaRecorder;
    setRecording(true);
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      if (audioContext) {
        audioContext.close().then(() => {
          setAudioContext(null);
          setAnalyser(null);
          setDataArray(null);
        });
      }
    }
  };

  const handleDreamInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    setWhisper(text);
  };

  return (
    <FlexCol>
      <canvas ref={canvasRef} width={350} height={200}></canvas>
      <br />
      {recording ? (
        <ButtonMorpheus onClick={stopRecording}>
          <span className="material-icons">stop</span>Stop Recording
        </ButtonMorpheus>
      ) : (
        <ButtonMorpheus onClick={startRecording}>
          <span className="material-icons">play_arrow</span>
          Start Recording
        </ButtonMorpheus>
      )}
      <Textarea
        onChange={handleDreamInput}
        // onKeyDown={handleKeyDown}
        placeholder={whisper ? whisper : "Écrivez votre rêve ici"}
        value={whisper ? whisper : ""}
      />
      {audioURL && <audio src={audioURL} controls />}
    </FlexCol>
  );
}

export default AudioRecorder;
