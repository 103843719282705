import axios from "axios";

const postArchiveDream = async (uid: string, dreamId: string) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/users/dreams/archive`;
  const response = await axios.post(url, {
    headers: {
      authorization: "Bearer token",
    },
    uid: uid,
    dreamId: dreamId,
  });

  return response;
};

export default postArchiveDream;
