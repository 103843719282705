const chat = async (
  style: string,
  dream: string,
  context: string,
  language: string,
  uid: string | null,
  ip: string | null
) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  if (!baseUrl) {
    throw new Error("Base URL is not defined.");
  }

  const url = `${baseUrl}/chat`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      // "Content-Type": "text/event-stream",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      style: style,
      dream: dream,
      context: context,
      language: language,
      uid: uid,
      ip: ip,
    }),
  });
  console.log(response.body);

  return response;
};

export default chat;

/*const config = new Configuration({
  apiKey: process.env.OPENAI_API_KEY
})
const openai = new OpenAIApi(config)

export const runtime = 'edge'

export async function POST(req) {
  const { messages } = await req.json()
  const response = await openai.createChatCompletion({
    model: 'gpt-4',
    stream: true,
    messages
  })
  const stream = OpenAIStream(response)
  return new StreamingTextResponse(stream)
}*/
