import React, { useState } from "react";
import axios from "axios";

function TextUploader() {
  const [text, setText] = useState("");
  const [message, setMessage] = useState("");

  const handleUpload = async () => {
    const baseUrl = process.env.REACT_APP_API_BASEURL_LOCAL;
    const apiUrl = `${baseUrl}/v1/text/upload`;

    const formData = new FormData();
    formData.append("sampleText", text);

    try {
      const response = await axios.post(apiUrl, formData);
      setMessage(response.data.message);
    } catch (error) {
      setMessage("Error uploading text.");
    }
  };

  return (
    <div>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter some text here..."
      />
      <button onClick={handleUpload}>Upload</button>
      <p>{message}</p>
    </div>
  );
}

export default TextUploader;
