const chatV2postWL = async (
  uid: string | null,
  firstname: string | null,
  lastname: string | null
) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  if (!baseUrl) {
    throw new Error("Base URL is not defined.");
  }

  const url = `${baseUrl}/v1/fr/users/chat/wl/add`;
  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      uid: uid,
      firstname: firstname,
      lastname: lastname,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json(); // or response.text() if it's a plain text response
    })
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.log(
        "There was a problem with the fetch operation:",
        error.message
      );
    });
};

export default chatV2postWL;
