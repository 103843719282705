import axios from "axios";
// import { generateRandomString } from "..";

// const token = process.env.REACT_APP_API_SECRET_KEY;

const writeCustomerId = async (
  uid: string,
  checkoutSessionId: string,
  plan: string
) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/subscription/customerId`;
  // const token = generateRandomString("test", 64);
  const response = await axios.post(url, {
    headers: {
      authorization: "Bearer token",
    },
    uid: uid,
    checkoutSessionId: checkoutSessionId,
    plan: plan,
  });

  return response;
};

export default writeCustomerId;
