import axios from "axios";
// const apiKey = "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";
const audioChat = async (audioBlob: Blob) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const apiUrl = `${baseUrl}/v1/audio/upload`;

  if (audioBlob) {
    const formData = new FormData();
    const mimeType = audioBlob.type;
    const fileExtension = mimeType.split("/")[1]; // assumes a MIME type structure like 'audio/webm'
    formData.append("file", audioBlob, `audio.${fileExtension}`); // 'file' field name

    const response = await axios.post(apiUrl, formData);
    return response.data.transcription;
  } else {
    return "No audio registered";
  }
};

// const audioChat = async (audioBlob: Blob) => {
//   const whisperUrl = "https://api.openai.com/v1/audio/transcriptions";
//   const model = "whisper-1";
//   const formData = new FormData();
//   formData.append("file", audioBlob, "audio.wav");
//   formData.append("model", model);

//   try {
//     console.log("whisper api start");
//     await axios
//       .post(whisperUrl, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${apiKey}`,
//         },
//       })
//       .then((response) => {
//         console.log(response.data);
//       });
//   } catch (error) {
//     console.log(error);
//   }
// };

// const audioChat = async (audioBlob: Blob) => {
//   const baseUrl = process.env.REACT_APP_API_BASEURL_LOCAL;
//   const apiUrl = `${baseUrl}/v1/audio/upload`;

//   if (audioBlob) {
//     try {
//       await axios.post(apiUrl, audioBlob).then((response) => {
//         console.log(response.data);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   }
// };

export default audioChat;
