import axios from "axios";

const getUnits = async (uid: string, ipPath: string) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/units`;
  const ip = ipPath.replace(/\./g, "_");
  if (uid === "") {
    const response = await axios.get(`${url}/ip-${ip}`);
    return response.data;
  } else if (ip === "") {
    const response = await axios.get(`${url}/uid-${uid}`);
    return response.data;
  } else {
    console.log("Error: Missing UID or IP.");
    return "Error";
  }
};

export default getUnits;
