import axios from "axios";

const readDreamsUser = async (uid: string) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/users/dreams/${uid}`;
  const response = await axios.get(url);
  return response.data;
};

export default readDreamsUser;
