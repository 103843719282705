import axios from "axios";

const chatV2getWL = async () => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/v1/wl/success`;
  const response = await axios.get(url);
  return response.data;
};

export default chatV2getWL;
