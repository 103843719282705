const chatV2 = async (message: string, chatId: string, uid: string | null) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  if (!baseUrl) {
    throw new Error("Base URL is not defined.");
  }

  const url = `${baseUrl}/v1/fr/users/chat`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      message: message,
      chatId: chatId,
      uid: uid,
    }),
  });
  console.log(response.body);

  return response;
};

export default chatV2;
