import React, { useEffect } from "react";
import {
  Card,
  CardBox,
  CardContent,
  CardContentBox,
  CardDescription,
  CardTitle,
} from "../Cards/Cards.styled";
import { MQEA } from "../UI/Text/mqea/mqea.styled";
import { BoxRow } from "../Box/Box.styled";
import { ButtonMorpheus } from "../ButtonMorpheus/ButtonMorpheus.styled";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { loadAuth } from "../../store/applications/AuthReducer";

function SubscriptionFR() {
  const dispatch = useAppDispatch();
  const authData = useAppSelector((state) => state.AuthReducer);

  useEffect(() => {
    dispatch(loadAuth());
  }, []); // eslint-disable-line

  return (
    <CardBox>
      <Card>
        <CardContentBox>
          <CardContent>
            <CardTitle>Infinite</CardTitle>
            <CardDescription>
              Profitez d'interprétations de rêves <MQEA>illimitées</MQEA> chaque
              mois et accédez à l'historique de vos rêves et leurs
              interprétations.
            </CardDescription>
            <BoxRow>
              <CardTitle textDecoration="line-through">14€</CardTitle>
              <CardDescription>/mois</CardDescription>
            </BoxRow>
            <BoxRow>
              <CardTitle>9€</CardTitle>
              <CardDescription>/mois</CardDescription>
            </BoxRow>
            <form
              action="https://us-central1-askmorpheusdreams.cloudfunctions.net/app/create-checkout-session"
              method="POST"
            >
              <input
                type="hidden"
                name="priceId"
                value="price_1NQBxuGCgTv56HC9IUGqw05G"
              />
              <input type="hidden" name="uid" value={authData.uid} />
              <input type="hidden" name="plan" value="infinite" />
              <ButtonMorpheus type="submit">Démarrez</ButtonMorpheus>
            </form>
          </CardContent>
        </CardContentBox>
      </Card>
      <Card>
        <CardContentBox>
          <CardContent>
            <CardTitle>Premium</CardTitle>
            <CardDescription>
              Profitez de <MQEA>15 interprétations</MQEA> de rêves par mois.
            </CardDescription>
            <BoxRow>
              <CardTitle textDecoration="line-through">9€</CardTitle>
              <CardDescription>/mois</CardDescription>
            </BoxRow>
            <BoxRow>
              <CardTitle>4€</CardTitle>
              <CardDescription>/mois</CardDescription>
            </BoxRow>
            <form
              action="https://us-central1-askmorpheusdreams.cloudfunctions.net/app/create-checkout-session" //"https://us-central1-askmorpheusdreams.cloudfunctions.net/app/create-checkout-session"
              method="POST"
            >
              <input
                type="hidden"
                name="priceId"
                value="price_1NQBxuGCgTv56HC9Xef3lrVU"
              />
              <input type="hidden" name="uid" value={authData.uid} />
              <input type="hidden" name="plan" value="premium" />
              <ButtonMorpheus type="submit">Démarrez</ButtonMorpheus>
            </form>
          </CardContent>
        </CardContentBox>
      </Card>
    </CardBox>
  );
}

export default SubscriptionFR;
