import React, { useEffect, useState } from "react";

import google from "../../assets/images/google.png";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import {
  LoginAction,
  LoginActionBox,
  LoginBox,
  LoginButton,
  LoginCitation,
  LoginPart1Box,
} from "./Login.styled";
import {
  FormBox,
  InlineInputBox,
  InputNeumorphic,
  Label,
  SelectBox,
  SelectLab,
  StyledOption,
} from "../../components/UI/Forms/Forms.styled";
import { writeNewUser, IUserData } from "../../utils/api/writeNewUser";
import Divider from "../../components/UI/Divider/Divider";
import ButtonNeumorphicRoot from "../../components/UI/ButtonNeumorphic/ButtonNeumorphic.styled";
import { MQEA } from "../../components/UI/Text/mqea/mqea.styled";
import {
  Modal,
  ModalBox,
  ModalLayer,
} from "../../components/UI/Modal/Modal.styled";
import { publicIpv4 } from "public-ip";
import { readUserPersonalData } from "../../utils/api/readUserData";

function LoginFR() {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-askmorpheus") === "true"
  );
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [birthday, setBirthday] = useState(
    new Date().toISOString().split("T")[0]
  );
  const genders = ["F", "M", "Other"];
  const [gender, setGender] = useState(genders[0]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [displayModal, setDisplayModal] = useState(false);

  let userData: IUserData = {
    createdAt: Date.now(),
    uid: "",
    pic: "",
    nom: "",
    prenom: "",
    gender: "",
    birthday: "",
    pays: "",
    ville: "",
    email: "",
    emailVerified: false,
    ip: "",
    subscription: "",
    customerId: "",
  };

  const provider = new GoogleAuthProvider();
  // const auth = getAuth();
  const loginWithGoogle = () => {
    signInWithPopup(auth, provider).then(async (userCred) => {
      const user = await readUserPersonalData(userCred.user.uid);
      if (user.uid === "") {
        console.log("not registered");
        const _nom = userCred.user.displayName?.split(" ")[0];
        const _prenom = userCred.user.displayName?.split(" ")[1];
        try {
          userData.createdAt = Date.now();
          userData.uid = userCred.user.uid;
          userData.pic = userCred.user.photoURL ? userCred.user.photoURL : "";
          userData.nom = typeof _nom === "undefined" ? "" : _nom;
          userData.prenom = typeof _prenom === "undefined" ? "" : _prenom;
          userData.gender = "";
          userData.email = userCred.user.email ? userCred.user.email : "";
          userData.birthday = "";
          userData.subscription = "free";
          const ipV4 = await publicIpv4();
          userData.ip = ipV4;
          console.log(userData);
          writeNewUser(userData, 0, userCred.user.uid).then();
        } catch (error) {
          console.log(error);
        }
      }
      window.localStorage.setItem("uid-askmorpheus", userCred.user.uid);
      window.localStorage.setItem("auth-askmorpheus", "true");
      setIsAuth(true);
    });
  };

  const createWithEmail = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCred) => {
        window.localStorage.setItem("uid-askmorpheus", userCred.user.uid);
        window.localStorage.setItem("auth-askmorpheus", "true");
        setIsAuth(true);
        try {
          userData.createdAt = Date.now();
          userData.uid = userCred.user.uid;
          userData.pic = `https://firebasestorage.googleapis.com/v0/b/askmorpheusdreams.appspot.com/o/askmorpheus-logo-128.png?alt=media&token=7a485330-6580-457a-a770-b3f55105802b`;
          userData.nom = nom
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          userData.prenom = prenom
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          userData.gender = gender;
          userData.ville = "";
          userData.email = email;
          userData.birthday = birthday;
          userData.subscription = "free";
          const ipV4 = await publicIpv4();
          userData.ip = ipV4;
          // params.uid && userData.amis.push(params.uid);
          writeNewUser(userData, 0, userCred.user.uid);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((e: { code: string; message: string }) => {
        console.log(e.code === "auth/email-already-in-use");
      });
  };

  const signinWithEmail = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCred) => {
        window.localStorage.setItem("uid-askmorpheus", userCred.user.uid);
        window.localStorage.setItem("auth-askmorpheus", "true");
        setIsAuth(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth]); // eslint-disable-line

  return (
    <LoginBox>
      <LoginPart1Box>
        <LoginActionBox>
          <LoginCitation>
            <MQEA>Morpheus</MQEA> plonge dans l'histoire de votre vie et le
            contexte actuel pour offrir une compréhension perspicace et
            personnalisée de vos voyages nocturnes.
          </LoginCitation>
        </LoginActionBox>
      </LoginPart1Box>
      <LoginActionBox>
        <h1 style={{ color: "#fff" }}>
          Entrez dans le royaume de vos rêves ... 🔮
        </h1>
        <p style={{ color: "#fff" }}>
          Découvrez un nouveau monde caché au cœur de vos rêves avec{" "}
          <MQEA>Ask Morpheus</MQEA>.
        </p>
        <p>
          Dès aujourd'hui, faites l'expérience d'une rencontre intime avec votre
          moi intérieur.
        </p>
        <p>
          Plongez dans la richesse insoupçonnée de vos songes et laissez-vous
          guider vers une compréhension profonde de vous-même.
        </p>
        <p style={{ color: "#fff" }}>
          Avec <MQEA>Ask Morpheus</MQEA>, l'exploration fascinante de votre
          subconscient démarre ici et maintenant !
        </p>
        <FormBox>
          <InputNeumorphic
            placeholder="E-mail"
            type="email"
            required
            onChange={(event) => setEmail(event.target.value)}
          />
          <InputNeumorphic
            placeholder="Mot de passe"
            type="password"
            required
            onChange={(event) => setPassword(event.target.value)}
          />
          <ButtonNeumorphicRoot
            // gradient1="rgb(233, 216, 166)"
            fontWeight="600"
            onClick={signinWithEmail}
          >
            Connexion
          </ButtonNeumorphicRoot>
          <Divider />
          <ButtonNeumorphicRoot
            // onClick={createWithEmail}
            onClick={() => setDisplayModal(!displayModal)}
            gradient1="#878ffb"
            fontWeight="600"
            colorText="rgb(36, 36, 36)"
            //gradient1="#6e78ff"
            //gradient2="#61f4de"
          >
            Inscription
          </ButtonNeumorphicRoot>
        </FormBox>
        <p style={{ textAlign: "center", width: "100%" }}>OU</p>
        <LoginAction>
          <LoginButton onClick={loginWithGoogle}>
            <img
              alt="Google Logo"
              src={google}
              style={{ width: "1.2rem", verticalAlign: "sub" }}
            />{" "}
            Inscription/Connexion avec Google
          </LoginButton>
        </LoginAction>
      </LoginActionBox>
      <ModalBox isDisplay={displayModal}>
        <ModalLayer onClick={() => setDisplayModal(!displayModal)}></ModalLayer>
        <Modal>
          <InlineInputBox>
            <InputNeumorphic
              placeholder="Prénom"
              type="text"
              required
              onChange={(event) => setPrenom(event.target.value)}
            />
            <InputNeumorphic
              placeholder="Nom"
              type="text"
              required
              onChange={(event) => setNom(event.target.value)}
            />
          </InlineInputBox>
          <InputNeumorphic
            placeholder="Email"
            type="email"
            required
            onChange={(event) => setEmail(event.target.value)}
          />
          <InputNeumorphic
            placeholder="Nouveau mot de passe"
            type="password"
            required
            onChange={(event) => setPassword(event.target.value)}
          />
          <SelectBox>
            <Label>Genre:</Label>
            <SelectLab
              value={gender}
              name={gender}
              onChange={(event) => {
                console.log(event.target.selectedOptions[0].index);
                setGender(event.target.selectedOptions[0].label);
              }}
            >
              {genders.map((gender, index) => (
                <StyledOption key={index} value={gender}>
                  {gender}
                </StyledOption>
              ))}
            </SelectLab>
          </SelectBox>
          <SelectBox>
            <Label>Date de Naissance:</Label>
            <InputNeumorphic
              placeholder="Date de naissance"
              type="date"
              value={birthday}
              required
              onChange={(event) => setBirthday(event.target.value)}
            />
          </SelectBox>
          <ButtonNeumorphicRoot
            gradient1="#878ffb"
            colorText="rgb(36,36,36)"
            fontWeight="600"
            onClick={createWithEmail}
          >
            Inscription
          </ButtonNeumorphicRoot>
        </Modal>
      </ModalBox>
    </LoginBox>
  );
}

export default LoginFR;
