import styled from "styled-components";

export const ButtonPR = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  position: relative;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 8px;
  font-weight: 600;
  height: 2.5rem;
  min-width: 2.5rem;
  font-size: 1rem;
  margin: 0.5rem 0;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background: ${(props) =>
    props.disabled
      ? "linear-gradient(rgba(140, 30, 30, 0.5) 0%, rgba(77, 21, 21, 0.5) 100%)"
      : "linear-gradient(rgb(140, 30, 30) 0%, rgb(77, 21, 21) 100%)"};
  border-width: 2px 0px 0px;
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-top-style: solid;
  border-top-color: ${(props) =>
    props.disabled ? "rgba(185, 47, 47, 0.5)" : "rgb(185, 47, 47)"};
  color: #ffffff;
  transition: transform 100ms ease-in-out 0s;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    transform: scale(1.03);
  }
`;
