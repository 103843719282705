import styled from "styled-components";

export const TablesBox = styled.div`
  display: grid;
  grid-template-columns: ${(props: { columns?: string }) =>
    props.columns
      ? `repeat(${props.columns}, minmax(0px, 1fr))`
      : "repeat(2, minmax(0px, 1fr))"};
  grid-template-rows: repeat(2, minmax(0px, 60vh));
  gap: 1.3rem;
  margin: 20px 0px;

  @media screen and (max-width: 621px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
    grid-template-rows: repeat(1, minmax(0px, 30vh));
  }
`;

export const TableBox = styled.div`
  overflow-y: scroll;
  max-height: 60vh;
`;
// export const Table = styled.table`
//   margin: 0 10px;
//   box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 50%),
//     -2px -2px 5px 0 rgb(255 255 255 / 15%);

//   td {
//     text-align: center;
//   }
// `;

export const TableInputNeumorphic = styled.input`
  margin: 10px 0px;
  padding: 10px;
  border-radius: 0.5rem;
  width: inherit;
  background-color: rgb(255 255 255/20%);
  border: none;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 50%),
    -2px -2px 5px 0 rgb(255 255 255 / 15%);
  outline: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;

  &::before {
    content: "\f111";
    color: rgb(255 255 255/10%);
    font-size: inherit;
  }

  &:hover {
    background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  &:focus {
    background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  &:not(:placeholder-shown) {
    background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }
`;

export const TableSelectBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const TableSelectLab = styled.select`
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  margin-left: 1rem;
  width: inherit;
  height: 2em;
  padding: 0 4em 0 1em;
  background: url("https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg")
      no-repeat right 0.8em center / 1.4rem,
    linear-gradient(to left, rgb(0 0 0/20%) 3em, rgb(255 255 255/40%) 3em);
  color: white;
  border-radius: 0.25em;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 50%),
    -2px -2px 5px 0 rgb(255 255 255 / 15%);

  cursor: pointer;
  /* <option> colors */
  option {
    color: inherit;
  }

  &::after {
    font-size: 1rem;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0d7";
    position: absolute;
    color: #fff;
    padding: 0 15px;
    background-color: #000;
    cursor: pointer;
    transition: all 0.3s;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
  /* appearance: none;
  width: 80%;
  outline: 0;
  border: 0;
  padding: 0 15px;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  } */
`;

interface IButtonNeumorphicProps {
  colorText?: string;
}

export const TableButtonNeumorphic = styled.button`
  min-width: 2.2rem;
  text-align: center;
  min-height: 2rem;
  padding: 0.05rem 0.25rem;
  margin: 0.3rem;
  //color: rgb(255, 255, 255);
  background: linear-gradient(310deg, rgb(0 0 0/10%), transparent);
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 50%),
    -2px -2px 5px 0 rgb(255 255 255 / 15%);
  display: inline-flex;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  color: ${({ colorText, ...rest }: IButtonNeumorphicProps) =>
    colorText || "rgb(255, 255, 255);"};
  transition: all 150ms ease-in-out 0s;
  // box-shadow: rgb(12 22 44 / 30%) 0px 9px 20px 1px;

  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  vertical-align: middle;
  appearance: none;
  letter-spacing: 0.02857em;
  line-height: 1.4;
  text-transform: uppercase;
  transition: all 150ms ease-in;
  flex-flow: row nowrap;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 50%),
      -1px -1px 3px 0 rgb(255 255 255 / 15%);
  }
`;

export const TableCellBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const TableInputBox = styled.div`
  display: flex;
  flex-direction: column;
`;

// A D V A C E D   T A B L E

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: inherit;

  @media screen and (max-width: 480px) {
    overflow-x: auto;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 0 10px;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 50%),
    -2px -2px 5px 0 rgb(255 255 255 / 15%);
`;

export const TableHead = styled.thead`
  background-color: #171411;
`;

export const TableRowCustom = styled.tr`
  background-color: rgb(0 0 255/10%);
  box-shadow: rgb(84 68 54) 0px 3px 0px, rgb(172 142 115) 0px -1px 0px,
    rgb(0 0 0) 0px 4px 8px -4px, rgb(135 107 83) 0px -1px 0px 1px;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  &:nth-of-type(odd) {
    background-color: rgb(255 255 255/10%);
  }
`;

export const TableRowLeaderBoard = styled.tr`
  &:first-child {
    background: linear-gradient(
      135deg,
      rgba(182, 145, 33, 0.7) 0%,
      transparent 35%,
      rgb(255 255 255/10%) 100%
    );
  }

  &:nth-child(2) {
    background: linear-gradient(
      135deg,
      rgba(173, 181, 189, 0.7) 0%,
      transparent 35%
    );
  }

  &:nth-child(3) {
    background: linear-gradient(
      135deg,
      rgba(192, 72, 39, 0.7) 0%,
      transparent 35%,
      rgb(255 255 255/10%) 100%
    );
  }
  &:nth-of-type(odd) {
    background-color: rgb(255 255 255/10%);
  }

  @media screen and (max-width: 480px) {
    &:first-child {
      background: transparent;
      background-color: rgba(182, 145, 33, 0.35);
    }

    &:nth-child(2) {
      background: transparent;
      background-color: rgba(173, 181, 189, 0.35);
    }

    &:nth-child(3) {
      background: transparent;
      background-color: rgba(192, 72, 39, 0.35);
    }
  }
`;

export const TableRowFooter = styled.tr`
  &:nth-of-type(odd) {
    background-color: rgb(255 255 255/10%);
  }
`;

export const TableCell = styled.td`
  padding: 8px;
  text-align: left;
`;

export const TableHeaderCell = styled.th`
  padding: 8px;
  text-align: left;
  background-color: #171411;
  cursor: pointer;
`;

export const TableHeaderCellContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableFooter = styled.tfoot``;

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
`;

export const PaginationButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
  border-radius: 10rem;
  padding: 0.6875rem 0.6875rem 0.625rem;
  // border: solid 1px #dee2e6;
  border: none;
  margin: 0 0.125rem;
  pointer-events: auto;
  font-weight: 400;
  font-size: 0.875rem;
  width: 2.25rem;
  min-width: 2.25rem;
  height: 2.25rem;
  min-height: 2.25rem;
  color: rgb(255, 255, 255);
  transition: all 150ms ease-in;
  background: linear-gradient(310deg, rgb(0 0 0/10%), transparent);
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 50%),
    -2px -2px 5px 0 rgb(255 255 255 / 15%);

  &:hover {
    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 50%),
      -1px -1px 3px 0 rgb(255 255 255 / 15%);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const TableOptions = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const RowSelection = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const RowSelectionLabel = styled.label`
  margin-right: 8px;
`;

export const RowSelectionSelect = styled.select`
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  margin-left: 1rem;
  height: 2em;
  padding: 0 3em 0 1em;
  background: url("https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg")
      no-repeat right 0.6em center / 1.2rem,
    linear-gradient(to left, rgb(0 0 0/20%) 2.4em, rgb(255 255 255/40%) 2.4em);
  color: white;
  border-radius: 0.25em;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 50%),
    -2px -2px 5px 0 rgb(255 255 255 / 15%);

  cursor: pointer;
  /* <option> colors */
  option {
    color: inherit;
  }

  &::after {
    font-size: 0.8rem;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0d7";
    position: absolute;
    color: #fff;
    padding: 0 15px;
    background-color: #000;
    cursor: pointer;
    transition: all 0.3s;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const SearchInput = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  font-size: 16px;
`;
