import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import TestPage from "./pages/TestPage/TestPage";
import Homepage from "./pages/Homepage/Homepage";
import Login from "./pages/Login/Login";
import HomepageFR from "./pages/Homepage/HomepageFR";
import LoginFR from "./pages/Login/LoginFR";
import DashboardAdmin from "./pages/DashboardAdmin/DashboardAdmin";
import DreamsList from "./pages/DreamsList/DreamsList";
import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import { setLanguage } from "./store/applications/UIReducer";
import InterpretV2 from "./pages/InterpretV2/InterpretV2";
import InterpreterV2admin from "./pages/DashboardAdmin/InterpreterV2admin";
import AudioRecorder from "./pages/AudioRecorder/AudioRecoder";
import TextUploader from "./pages/TextUploader/TextUploader";

function App() {
  const dispatch = useAppDispatch();
  const UI = useAppSelector((state) => state.UIReducer);
  useEffect(() => {
    const userLang = navigator.language.split("-")[0];
    dispatch(setLanguage(userLang));
  }, [dispatch]);
  return (
    <BrowserRouter>
      <BaseLayout>
        <Routes>
          <Route path="/pr/test" element={<TestPage />} />
          <Route path="/v2" element={<InterpretV2 />} />
          <Route path="/v2/audio" element={<AudioRecorder />} />
          <Route path="/v2/text" element={<TextUploader />} />
          <Route path="/v2/admin" element={<InterpreterV2admin />} />
          <Route path="/v2/c/:chatIdParam" element={<InterpretV2 />} />
          {/* <Route path="/" element={<SelectLanguage />} /> */}
          <Route
            path="/"
            element={UI.language === "fr" ? <HomepageFR /> : <Homepage />}
          />
          <Route path="/en" element={<Homepage />} />
          <Route path="/fr" element={<HomepageFR />} />
          <Route path="/login" element={<Login />} />
          <Route path="/fr/login" element={<LoginFR />} />
          <Route path="/dreams" element={<DreamsList />} />
          <Route path="/admin/dashboard" element={<DashboardAdmin />} />
        </Routes>
      </BaseLayout>
    </BrowserRouter>
  );
}

export default App;
