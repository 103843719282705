import axios from "axios";

const chatV2getAll = async (uid: string) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/v1/fr/users/chat/${uid}`;
  const response = await axios.get(url);
  return response.data;
};

export default chatV2getAll;
