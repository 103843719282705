import axios from "axios";

const postFeedback = async (
  uid: string,
  ip: string,
  dreamId: string,
  relevant: boolean
) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/dreams/feedback`;
  const response = await axios.post(url, {
    headers: {
      authorization: "Bearer token",
    },
    uid: uid,
    ip: ip,
    dreamId: dreamId,
    relevant: relevant,
  });

  return response;
};

export default postFeedback;
