import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  PageDescription,
  PageTitleBox,
} from "../../components/Paragraph/Paragraph.styled";

import chat from "../../utils/api/chat";
import {
  Label,
  SelectBox,
  SelectLab,
  StyledOption,
  Textarea,
} from "../../components/UI/Forms/Forms.styled";
import { ButtonMorpheus } from "../../components/ButtonMorpheus/ButtonMorpheus.styled";
import {
  Interpretation,
  InterpretationBox,
} from "../../components/Interpretation/Interpretation.style";
import { Dna } from "react-loader-spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import ButtonNeumorphicRoot from "../../components/UI/ButtonNeumorphic/ButtonNeumorphic.styled";
import { Link, useSearchParams } from "react-router-dom";
import Divider from "../../components/UI/Divider/Divider";
import { publicIpv4 } from "public-ip";
import getUnits from "../../utils/api/getUnits";
import { MQEA } from "../../components/UI/Text/mqea/mqea.styled";
import { LogoImg } from "../../components/Header/Header";
import morpheus from "../../assets/images/morpheus_dreams_god.png";
import { loadAuth } from "../../store/applications/AuthReducer";
import { PageSubTitleH3 } from "../../components/UI/Text/Text.styled";
import { BoxRow } from "../../components/Box/Box.styled";
import {
  SuccessBox,
  SuccessIcon,
  SuccessText,
} from "../../components/Success/Success.styled";
import checkButton from "../../assets/images/icons/check-button.png";
import writeCustomerId from "../../utils/api/writeCustomerId";
import SubscriptionFR from "../../components/Subscription/SubscriptionFR";
import postFeedback from "../../utils/api/postFeedback";
import Panel from "../../components/Panel/Panel";
import Versions from "../../components/Versions/Versions";

export const styles = [
  "Sigmund Freud",
  "Carl Jung",
  "Frederick Perls",
  "Alfred Adler",
  "Ibn Sirin - Islam",
  "Joseph - Biblique",
  // "Lacan",
  // "Tarot",
  // "Christian & Spirits",
  // "Islamic & Spirits",
  // "Jewish Talmud & Spirits",
  // "Kabbalah",
  // "Celtic",
  // "African & Voodoo",
  "Féministe",
];

export const languages = [
  "Français",
  "English",
  "Español",
  "Italiano",
  "Portugues",
  "Deutsche",
  "العربية",
];

export const LogoLang = styled.img`
  border-radius: 1rem;

  @media screen and (min-width: 0px) {
    width: 35px;
    height: 35px;
  }
`;

export const HomepageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ChatBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

export const SendButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const UpgradeBox = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
`;

export const UpgradeCard = styled.div``;

function HomepageFR() {
  const [queryParameters] = useSearchParams();
  const dispatch = useAppDispatch();
  const authData = useAppSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(false);
  const [valueClean, setValueClean] = useState("");
  const [interpretation, setInterpretation] = useState("");
  const [dreamId, setDreamId] = useState("");
  const [style, setStyle] = useState("Freud");
  const [dream, setDream] = useState("");
  const [context, setContext] = useState("");
  const [relevant, setRelevant] = useState("");
  const [language, setLanguage] = useState("Français");
  // eslint-disable-next-line
  const [uid, setUid] = useState<string | null>(
    "" || window.localStorage.getItem("uid-askmorpheus")
  );
  const [ip, setIp] = useState<string | null>("");
  const [unitsCounter, setUnitsCounter] = useState(0);
  const [update, setUpdate] = useState(0);
  // eslint-disable-next-line
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-askmorpheus") === "true"
  );
  const [priceTable, setPriceTable] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [successSub, setSuccessSub] = useState(false);

  useEffect(() => {
    // console.log(uid);
    // console.log(authData.uid);
    dispatch(loadAuth());
    if (uid === "" || (uid === null && authData.uid !== "")) {
      setUid(authData.uid);
    }
    // console.log(uid);
  }, [uid]); // eslint-disable-line

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const paramSuccess = queryParameters.get("success");
    const paramCancel = queryParameters.get("canceled");
    const plan = queryParameters.get("plan");

    if (paramSuccess) {
      const param = queryParameters.get("session_id");
      setSuccessSub(true);
      param && setSessionId(param);
    }

    if (plan) {
      const param = queryParameters.get("session_id");
      console.log(uid, param, plan);
      const writeCusId = async () => {
        param && uid && writeCustomerId(uid, param, plan);
      };
      writeCusId().then(() => dispatch(loadAuth()));
    }

    if (paramCancel) {
      setSuccessSub(false);
      // setMessage(
      //   "Order canceled -- continue to shop around and checkout when you're ready."
      // );
    }
  }, [sessionId]); // eslint-disable-line

  useEffect(() => {
    const setUnits = async () => {
      try {
        const ipV4 = await publicIpv4();
        setIp(ipV4);
        if (uid) {
          const units = await getUnits(uid, "");
          setUnitsCounter(units);
        } else {
          const units = await getUnits("", ipV4);
          setUnitsCounter(units);
        }
      } catch (error) {
        const ipV4 = "0.0.0.0";
        setIp(ipV4);
        if (uid) {
          const units = await getUnits(uid, "");
          setUnitsCounter(units);
        } else {
          const units = await getUnits("", ipV4);
          setUnitsCounter(units);
        }
      }
    };

    setUnits();
    console.log(unitsCounter);
    // console.log(uid);
  }, [unitsCounter, update]); // eslint-disable-line

  const handleDreamInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    setDream(text);
  };

  const handleContextInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    setContext(text);
  };

  const handleClick = async () => {
    setValueClean("");
    setRelevant("");
    setInterpretation("");
    setDreamId("");
    setCurrentIndex(0);
    setCurrentText("");
    setLoading(true);
    const response = await chat(style, dream, context, language, uid, ip);
    console.log(response);

    if (window.TextDecoderStream) {
      const reader = response.body
        ?.pipeThrough(new TextDecoderStream())
        ?.getReader();
      let received = "";
      if (reader) {
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          received += value;
          setLoading(false);
          // console.log("Received: ", value);
          setValueClean((prev) => prev + value);
          setUpdate(update + 1);
          if (value.includes("interpretationId")) {
            const interpretationId = value.replace("\ninterpretationId: ", "");
            setDreamId(interpretationId);

            const lines = received.split("\ninterpretationId: ");
            const _interpretation = lines[0];
            setInterpretation(_interpretation);
          }
        }
      }
    } else {
      // Fallback or notify the user that their browser isn't supported.
      const data = await response.arrayBuffer();
      const text = new TextDecoder().decode(data);

      setValueClean(text);
      if (text.includes("interpretationId")) {
        const interpretationId = text.replace("\ninterpretationId: ", "");
        setDreamId(interpretationId);

        const lines = text.split("\ninterpretationId: ");
        const _interpretation = lines[0];
        setInterpretation(_interpretation);
      }

      setLoading(false);
    }
  };

  const handleUpgradeClick = () => {
    setPriceTable(!priceTable);
  };

  const handlePositifFeedback = async () => {
    setRelevant("positif");
    if (uid) {
      await postFeedback(uid, "0.0.0.0", dreamId, true);
    } else if (ip) {
      await postFeedback("", ip, dreamId, true);
    }
  };

  const handleNegatifFeedback = async () => {
    setRelevant("negatif");
    if (uid) {
      await postFeedback(uid, "0.0.0.0", dreamId, false);
    } else if (ip) {
      await postFeedback("", ip, dreamId, false);
    }
  };

  const speed = 10;
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < interpretation.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + interpretation[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, speed);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, speed, interpretation]);

  return (
    <HomepageBox>
      <Versions whichVersion={1} />

      {!isAuth ? (
        unitsCounter < 2 ? (
          <></>
        ) : (
          <>
            <PageDescription textAlign="center">
              🇫🇷 Vous avez atteint les limites des interprétations offertes.{" "}
              <br /> Veuillez vous inscrire ou vous connectez pour continuer à
              utiliser <MQEA>Ask Morpheus</MQEA>.
            </PageDescription>
          </>
        )
      ) : (
        <></>
      )}

      {!isAuth ? (
        <>
          <PageDescription textAlign="center">
            <b>
              🇫🇷Interprétations Offertes:
              <br />
              {unitsCounter >= 0 && unitsCounter < 3 ? 2 - unitsCounter : 0}/2
            </b>
          </PageDescription>
          <Link to="fr/login">
            <ButtonMorpheus>Inscription/Connexion</ButtonMorpheus>
          </Link>
        </>
      ) : (
        <>
          <PageDescription textAlign="center">
            <b>{authData.prenom + " " + authData.nom}</b> 👋 (
            {authData.subsription}
            {authData.subsription === "free"
              ? ` - ${10 - unitsCounter}
          /10`
              : authData.subsription === "circle" ||
                authData.subsription === "standard"
              ? ` - ${15 - unitsCounter}
            /15`
              : "✨"}
            )
          </PageDescription>
          {(authData.subsription === "free" ||
            authData.subsription === "circle" ||
            authData.subsription === "premium") && (
            <ButtonNeumorphicRoot
              gradient1="#878ffb"
              colorText="rgb(36, 36, 36)"
              fontWeight="600"
              onClick={handleUpgradeClick}
            >
              Upgrade
            </ButtonNeumorphicRoot>
          )}

          {successSub && (
            <SuccessBox>
              <SuccessIcon src={checkButton} />
              <SuccessText>Subscription updated successfully!</SuccessText>
            </SuccessBox>
          )}
        </>
      )}

      <Panel language="fr">
        <div>
          <p>
            <MQEA>Morpheus</MQEA> est une figure de la mythologie grecque,
            reconnue comme le dieu des rêves. En s'inspirant de ce nom, la web
            app <MQEA>Ask Morpheus</MQEA> vise à devenir la référence moderne en
            matière d'interprétation des rêves.
          </p>
          <p>
            Grâce à l'évolution technologique, <MQEA>Ask Morpheus</MQEA> utilise
            l'intelligence artificielle pour analyser et interpréter les rêves.
            En tenant compte des motifs courants, des contextes culturels et des
            émotions associées, cette IA offre une interprétation personnalisée
            et approfondie.
          </p>
          <p>
            <b>Instructions:</b>
          </p>

          <ul>
            <li>
              Plus la description du rêve est étayée, plus l'interprétation sera
              pertinente.
            </li>
            <br />
            <li>
              Si dans votre rêve il y a des personnes de votre entourage,
              décrivez la relation que vous avez avec elle. Par exemple: au lieu
              d'écrire "J'ai rêvé de Jean, on était dans la voiture", il est
              préférable d'écrire, "J'ai rêvé de <b>mon cousin</b> Jean, on
              était dans la voiture"
            </li>
          </ul>
        </div>
      </Panel>

      {priceTable ? <SubscriptionFR /> : <></>}
      <Divider />
      <SelectBox>
        <Label>Méthode:</Label>
        <SelectLab
          value={style}
          name={style}
          onChange={(event) => {
            setStyle(event.target.selectedOptions[0].label);
          }}
        >
          {styles.map((style, index) => (
            <StyledOption key={index} value={style}>
              {style}
            </StyledOption>
          ))}
        </SelectLab>
      </SelectBox>
      <SelectBox>
        <Label>Langue:</Label>
        <SelectLab
          value={language}
          name={language}
          onChange={(event) => {
            setLanguage(event.target.selectedOptions[0].label);
          }}
        >
          {languages.map((language, index) => (
            <StyledOption key={index} value={language}>
              {language}
            </StyledOption>
          ))}
        </SelectLab>
      </SelectBox>
      <ChatBox>
        <InterpretationBox>
          {authData && authData.pic && <LogoImg src={authData.pic} />}
          <div>
            <Label>Rêve:</Label>
            <Textarea
              onChange={handleDreamInput}
              // onKeyDown={handleKeyDown}
              placeholder="Écrivez ici votre rêve"
            />
          </div>
        </InterpretationBox>
        <Divider />

        <InterpretationBox>
          {authData && authData.pic && <LogoImg src={authData.pic} />}
          <div>
            <Label>Contexte:</Label>
            <Textarea
              onChange={handleContextInput}
              // onKeyDown={handleKeyDown}
              placeholder="Décrivez votre contexte personnel"
            />
          </div>
        </InterpretationBox>
        {loading ? (
          <LoaderBox>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </LoaderBox>
        ) : dream.length > 15 ? (
          !isAuth ? (
            unitsCounter < 2 ? (
              <SendButtonBox>
                <ButtonMorpheus onClick={handleClick}>
                  Envoyer <span className="material-icons">arrow_upward</span>
                </ButtonMorpheus>
              </SendButtonBox>
            ) : (
              <Link to="login">
                <ButtonMorpheus>Inscription/Connexion</ButtonMorpheus>
              </Link>
            )
          ) : (
            <SendButtonBox>
              <ButtonMorpheus onClick={handleClick}>
                Envoyer <span className="material-icons">arrow_upward</span>
              </ButtonMorpheus>
            </SendButtonBox>
          )
        ) : (
          <SendButtonBox>
            <ButtonMorpheus disabled>
              Envoyer <span className="material-icons">arrow_upward</span>
            </ButtonMorpheus>
          </SendButtonBox>
        )}
      </ChatBox>

      {valueClean && (
        <>
          <PageTitleBox>
            <PageSubTitleH3>Interprétation par Morpheus:</PageSubTitleH3>
            {/* <PageDescription>{dreamId}</PageDescription> */}
          </PageTitleBox>
          <br />
          <InterpretationBox>
            <LogoImg src={morpheus} />
            <Interpretation style={{ whiteSpace: "pre-wrap" }}>
              {/* {interpretation ? interpretation : valueClean} */}
              {interpretation ? currentText : valueClean}
            </Interpretation>
          </InterpretationBox>
        </>
      )}
      {/* <ButtonNeumorphicRoot
        gradient1="#878ffb"
        colorText="rgb(36, 36, 36)"
        fontWeight="600"
        onClick={handleUpgradeClick}
      >
        Upgrade
      </ButtonNeumorphicRoot> */}
      {
        // Display Upgrade Button for "free" sub and counter greater than 10
        authData.subsription === "free" && unitsCounter > 10 && (
          <ButtonMorpheus onClick={handleUpgradeClick}>Upgrade</ButtonMorpheus>
        )
      }

      {
        // Display Upgrade Button for "premium"/"circle" sub and counter greater than 15
        (authData.subsription === "circle" ||
          authData.subsription === "premium") &&
          unitsCounter > 15 && (
            <ButtonMorpheus onClick={handleUpgradeClick}>
              Upgrade
            </ButtonMorpheus>
          )
      }
      {/* <PageTitleBox>
        <PageSubTitleH3>Interprétation par Morpheus:</PageSubTitleH3>
        <PageDescription>{dreamId}</PageDescription>
      </PageTitleBox>
      <br />
      <InterpretationBox>
        <LogoImg src={morpheus} />
        <Interpretation style={{ whiteSpace: "pre-wrap" }}>
          {interpretation ? interpretation : value}
        </Interpretation>
      </InterpretationBox> */}

      {interpretation &&
        (relevant !== "" ? (
          <>
            <PageDescription>
              {relevant === "positif" ? "👍" : "👎"}
            </PageDescription>
          </>
        ) : (
          <>
            <PageDescription>
              <b>L'interprétation est-elle pertinente ?</b>
            </PageDescription>
            <BoxRow>
              <ButtonNeumorphicRoot onClick={handlePositifFeedback}>
                👍
              </ButtonNeumorphicRoot>
              <ButtonNeumorphicRoot onClick={handleNegatifFeedback}>
                👎
              </ButtonNeumorphicRoot>
            </BoxRow>
          </>
        ))}
      {/* <ButtonNeumorphicRoot
        onClick={() => {
          setRelevant("");
        }}
      >
        Test
      </ButtonNeumorphicRoot> */}
    </HomepageBox>
  );
}

export default HomepageFR;
