import styled from "styled-components";
import curved from "../../assets/images/curved-images/white-curved.jpeg";

export const CardBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0px, 1fr));

  @media screen and (max-width: 460px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
`;

export const Card = styled.div`
  background-color: #000;
  background-image: url(${curved});
  background-position: 10% center;
  background-size: cover;
  border-radius: 1rem;
  font-size: 12px;
  box-shadow: ${(props: { color?: string }) =>
    props.color
      ? `5px 5px 18px ${props.color}`
      : "5px 5px 18px rgba(0, 0, 0, 0.3)"};
`;

export const CardContentBox = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    background-image: ${(props: { color1?: string; color2?: string }) =>
      props.color1 && props.color2
        ? `linear-gradient(310deg, ${props.color1}, ${props.color2})`
        : "linear-gradient(310deg, rgb(1 5 23), rgb(12 19 63))"};
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.9;
    z-index: -1;
  }
`;

export const CardContent = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface ICardTitleProps {
  textDecoration?: string;
}
export const CardTitle = styled.h2`
  text-align: center;
  font-size: 1.6rem;
  margin: 0.5rem 0;
  text-decoration: ${(props: ICardTitleProps) =>
    props.textDecoration || "none"};
`;

export const CardDescription = styled.p`
  text-align: left;
  margin: 0.5rem 0;
  color: rgb(255 255 255 / 70%);
  font-size: 0.9rem;
`;

export const CardButton = styled.button`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
  border: 0;
  margin: 0;
  vertical-align: middle;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  background-size: 150% !important;
  background-position-x: 25% !important;
  transition: all 150ms ease-in;
  min-height: 2.5rem;
  box-shadow: 0rem 0.25rem 0.4375rem -0.0625rem rgb(0 0 0 / 11%),
    0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 7%);
  padding: 0.75rem 1.5rem;
  color: white !important;
  background-image: linear-gradient(
      -220deg,
      rgba(249, 231, 187, 0.9),
      rgba(233, 124, 187, 0.9),
      rgba(61, 71, 217, 0.9)
    ),
    url(${curved});

  margin: 0.5rem;
  min-width: 102px;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
    box-shadow: 0rem 0.25rem 0.4375rem -0.0625rem rgb(255 255 255 / 11%),
      0rem 0.125rem 0.25rem -0.0625rem rgb(255 255 255 / 7%);
  }
`;
