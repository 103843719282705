import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonPR } from "../../components/Button_PR/Button_PR";
// import { generateRandomString } from "../../utils";

const TestBox = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function TestPage() {
  const [randomStr, setRandomStr] = useState("");
  useEffect(() => {
    setRandomStr("abc");
  }, [randomStr]);

  return (
    <TestBox>
      Test Page
      <ButtonPR>Test</ButtonPR>
      <p>{randomStr}</p>
    </TestBox>
  );
}

export default TestPage;
