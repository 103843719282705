export const routes = [
  { type: "title", title: "Ask Morpheus", key: "title-docs" },
  {
    type: "collapse",
    name: "Interpret Dreams",
    key: "interpret",
    icon: "history_edu",
    route: "/",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "My Dreams",
    key: "dreams",
    icon: "lens_blur",
    route: "/dreams",
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "All Sessions",
  //   key: "sessions",
  //   icon: "view_list",
  //   route: "/sessions",
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Get NFT",
  //   key: "get_nft",
  //   href: "https://joepegs.com/collections/plaguegame",
  //   icon: "auto_awesome",
  //   noCollapse: true,
  // },
];

export const routesFR = [
  { type: "title", title: "Ask Morpheus", key: "title-docs" },
  {
    type: "collapse",
    name: "Interpréter mon rêve",
    key: "interpret",
    icon: "history_edu",
    route: "/",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Historique des rêves",
    key: "dreams",
    icon: "lens_blur",
    route: "/dreams",
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "All Sessions",
  //   key: "sessions",
  //   icon: "view_list",
  //   route: "/sessions",
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Get NFT",
  //   key: "get_nft",
  //   href: "https://joepegs.com/collections/plaguegame",
  //   icon: "auto_awesome",
  //   noCollapse: true,
  // },
];
