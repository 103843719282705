import React, { useState } from "react";
import Divider from "../Divider/Divider";
import { ListElement } from "../List/List";
import {
  DrawerArea,
  DrawerBox,
  DrawerHighlightBox,
  DrawerListTitle,
  DrawerLogoBox,
  DrawerLogoImg,
  // DrawerLogoImg,
  DrawerLogoLink,
  IDrawer,
} from "./Drawer.styled";

import logo from "../../assets/images/askmorpheus-logo-v2-1.png";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import SidenavCollapse from "./SidenavCollapse";
import { ICollapses } from "./DrawerInterface";
import SidenavItem from "./SidenavItem";
import SidenavList from "./SidenavList";
import { openDrawerMobile } from "../../store/applications/UIReducer";
import { HeaderDrawerTogglerTablet } from "../Header/Header.styled";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import ButtonNeumorphicRoot from "../UI/ButtonNeumorphic/ButtonNeumorphic.styled";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { loadAuthEmpty } from "../../store/applications/AuthReducer";
import { palette } from "../../assets/style/colors";

export const ListLiElement = styled.li`
  color: #fff;
  display: flex;
`;

export function logout() {
  window.localStorage.removeItem("uid-askmorpheus");
  window.localStorage.removeItem("auth-askmorpheus");
  return signOut(auth);
}

function Drawer({ drawerDesktop, drawerMobile, routes }: IDrawer) {
  const dispatch = useAppDispatch();
  const UI = useAppSelector((state) => state.UIReducer);
  const authData = useAppSelector((state) => state.AuthReducer);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];
  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: ICollapses[]) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <a
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </a>
      ) : (
        <a href={route} key={key} style={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </a>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses: ICollapses[]) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse}
            onClick={() => {
              setOpenNestedCollapse(!openNestedCollapse);
            }}
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <a href={href} key={key} style={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={key === itemName} />
          </a>
        ) : (
          <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    // collapse,
    ({ type, name, icon, title, noCollapse, collapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <a
              href={href}
              key={key}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </a>
            // <Link
            //   href={{ pathname: href }}
            //   key={key}
            //   style={{ textDecoration: "none" }}
            //   target="_blank"
            // >
            //   <SidenavCollapse
            //     name={name}
            //     icon={icon}
            //     active={key === collapseName}
            //     noCollapse={noCollapse}
            //   />
            // </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse}
              onClick={() => {
                setOpenCollapse(!openCollapse);
              }}
            >
              {/* {collapse ? renderCollapse(collapse) : null} */}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = <DrawerListTitle key={key}>{title}</DrawerListTitle>;
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    }
  );
  return (
    <DrawerArea>
      <DrawerBox
        drawerDesktop={drawerDesktop}
        drawerMobile={drawerMobile}
        routes={routes}
      >
        <HeaderDrawerTogglerTablet
          onClick={() => dispatch(openDrawerMobile(!UI.drawerMobile))}
        >
          <span style={{ color: "white" }} className="material-icons-outlined">
            {UI.drawerMobile ? "menu_open" : "menu"}
          </span>
        </HeaderDrawerTogglerTablet>
        <DrawerLogoBox>
          <DrawerLogoLink>
            <DrawerLogoImg src={logo} alt="potion run logo" />
          </DrawerLogoLink>
        </DrawerLogoBox>
        <Divider />
        <ListElement>{renderRoutes}</ListElement>
        {authData.customerId !== "" && (
          <form
            // https://us-central1-askmorpheusdreams.cloudfunctions.net/app
            // http://localhost:5001/askmorpheusdreams/us-central1/app
            action="https://us-central1-askmorpheusdreams.cloudfunctions.net/app/create-portal-session"
            method="POST"
          >
            <input type="hidden" id="uid" name="uid" value={authData.uid} />
            <ButtonNeumorphicRoot
              id="checkout-and-portal-button"
              type="submit"
              colorText={palette.primary.headline}
              style={{ fontSize: "0.7rem", width: "93%" }}
            >
              {UI.language === "fr"
                ? "Gérer mon abonnement"
                : "Manage your billing information"}
            </ButtonNeumorphicRoot>
          </form>
        )}
        {authData.uid !== "" && (
          <ButtonNeumorphicRoot
            gradient1="#878ffb"
            colorText={palette.primary.headline}
            fontWeight="600"
            onClick={() =>
              logout().then(() => {
                dispatch(loadAuthEmpty());
                window.location.reload();
              })
            }
          >
            {UI.language === "fr" ? "Déconnexion" : "Logout"}
          </ButtonNeumorphicRoot>
        )}
        <DrawerHighlightBox></DrawerHighlightBox>
      </DrawerBox>
    </DrawerArea>
  );
}

// {routes.map((r, index) => {
//   return (
//     <ListLiElement key={index}>
//       <span className="material-icons">{r.icon}</span>
//       <p>{r.name}</p>
//     </ListLiElement>
//   );
// })}

export default Drawer;
